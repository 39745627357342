import PropTypes from "prop-types";
import React, { useContext, useState, useEffect, useCallback } from "react";
import { useQueryParam, NumberParam } from "use-query-params";

import Tabs, { SubTabs } from "./tabs";
import styles from "./grfDashboards.module.css";

import { useSettings } from "../hooks";
import { CurrentUserContext } from "../providers/auth";
import { getDataAnalysisLinks } from "../utils/user";

const GrfDashboards = ({ dashboardType, link }) => {
    const { GRF_SAME_ORIGIN } = useSettings();
    const currentUser = useContext(CurrentUserContext);
    const [tabQp, setTabQp] = useQueryParam("tab", NumberParam);

    const getDashboardTab = () => {
        const tab = dashboardType[tabQp];
        if (tab) {
            return tab;
        } else {
            setTabQp(0);
            return dashboardType[0];
        }
    };

    const [user, setUser] = useState(null);
    const [dashboard, setDashboard] = useState(getDashboardTab());
    const [links, setLinks] = useState([]);

    const dashboardActions = () =>
        dashboardType.map((d, idx) => {
            if (d.name !== dashboard.name) {
                return {
                    name: d.name,
                    action: () => {
                        setDashboard(d);
                        setTabQp(idx);
                    }
                };
            } else {
                return { name: d.name, active: true };
            }
        });

    const iframeRef = useCallback((node) => {
        // add event listener to iframe for updating user active status only if same origin
        if (node !== null && GRF_SAME_ORIGIN) {
            const resetIdleTimer = () => {
                if (currentUser) {
                    currentUser.resetIdleTimer();
                }
            };
            node.addEventListener("load", () => {
                [
                    "keydown",
                    "wheel",
                    "DOMMouseScroll",
                    "mousewheel",
                    "mousedown",
                    "touchstart",
                    "MSPointerDown"
                ].forEach((e) => {
                    node.contentWindow.addEventListener(
                        e,
                        resetIdleTimer,
                        false
                    );
                });
            });
        }
    }, []);

    useEffect(() => {
        if (currentUser) {
            currentUser.getUser().then((u) => {
                if (!u) {
                    return;
                }
                setUser(u.profile);
                setLinks(getDataAnalysisLinks(u.profile, link));
            });
        }
    }, [currentUser]);

    return (
        <>
            <div className="px-4 pt-3 lg:px-8">
                <Tabs links={links} />
            </div>
            <div className="bg-gray-grf pb-4">
                <div className="py-3 px-6 lg:px-10">
                    <SubTabs actions={dashboardActions()} />
                </div>
                {user && (
                    <iframe
                        id="iframe"
                        className={styles.grfIframe}
                        src={dashboard.url}
                        ref={iframeRef}
                    ></iframe>
                )}
            </div>
        </>
    );
};

export default GrfDashboards;

GrfDashboards.propTypes = {
    dashboardType: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string
        })
    ).isRequired,
    link: PropTypes.string.isRequired
};
