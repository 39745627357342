import React, { useEffect, useContext, useState } from "react";

import Layout from "../../components/layout";
import GrfDashboards from "../../components/grfDashboards";

import { useSettings } from "../../hooks";
import Links from "../../utils/links";
import UserUtil from "../../utils/user";
import { CurrentUserContext } from "../../providers/auth";

const AnalysisRawPage = () => (
    <Layout
        link={Links.DataAnalysis}
        seoTitle="Raw Data Analysis"
        mainDivCss=""
    >
        <AnalysisRaw />
    </Layout>
);

export default AnalysisRawPage;

const AnalysisRaw = () => {
    const { grfDashboard } = useSettings();
    const currentUser = useContext(CurrentUserContext);
    const [userProfile, setUserProfile] = useState(null);

    const dashboardType = () => {
        const { raw } = grfDashboard;
        return [
            { name: "By Lamppost", url: raw.byLamppost },
            { name: "By Lamppost (Avg)", url: raw.byLamppostAvg },
            { name: "By Pollutant", url: raw.byPollutant },
            { name: "By Pollutant (Avg)", url: raw.byPollutantAvg },
            { name: "Sensor Data", url: raw.sensor }
        ].concat(
            UserUtil.hasDataOperatorPrivileges(userProfile)
                ? [{ name: "Sensor Parameters", url: raw.sensorParams }]
                : []
        );
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        currentUser
            .getUser()
            .then((user) => user && setUserProfile(user.profile));
    }, [currentUser]);

    return (
        <>
            {userProfile && (
                <GrfDashboards
                    dashboardType={dashboardType()}
                    link={Links.DaRaw}
                />
            )}
        </>
    );
};
